import localFont from 'next/font/local'

const megalona = localFont({
  src: [
    { path: '../../public/fonts/BDMegalona-Regular.woff2', style: 'normal', weight: '400' },
    { path: '../../public/fonts/BDMegalona-Medium.woff2', style: 'normal', weight: '500' },
    { path: '../../public/fonts/BDMegalona-SemiBold.woff2', style: 'normal', weight: '600' },
  ],
  variable: '--font-serif',
})

export default megalona.variable
